import { ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER, FiatCurrency } from '@bp/shared/models/currencies';
import {
	Control, Default, FieldControlType, FieldViewType, Mapper, View, ViewEmptyValue, DTO
} from '@bp/shared/models/metadata';

import { PaymentOptionType } from '@bp/frontend/models/business';

import { RoutePspPaymentOptionBase } from './route-psp-payment-option-base';

export class RoutePspPaymentOptionWalletApplePay extends RoutePspPaymentOptionBase {

	static type = PaymentOptionType.walletApplePay;

	readonly type = RoutePspPaymentOptionWalletApplePay.type;

	@Mapper(FiatCurrency)
	@Default([])
	@View(FieldViewType.boxedItems)
	@Control(
		FieldControlType.chip,
		{
			required: true,
			placeholder: ADD_FIAT_CURRENCY_CHIP_PLACEHOLDER,
		},
	)
	@ViewEmptyValue('Any')
	currencies!: FiatCurrency[];

	constructor(dto?: DTO<RoutePspPaymentOptionWalletApplePay>) {
		super(dto);
	}

}
